@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'EB Garamond', serif;
  font-weight: 400;
}

html {
  --scrollbarBG: white;
  --thumbBG: #222;
  --BgColor: #faf0e6;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
  padding: 0 1px;
}

.max-w-1200 {
  max-width: 1200px;
}

.go-next-grad {
  background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.4))
}